<template>
  <div>
    <div class="flex-def flex-cCenter flex-zBetween">
      <div class="y-desc" style=""></div>
      <div class="flex-def flex-cCenter">
        <div style="margin-right: 1rem" class="y-pointer y-desc flex-def flex-cCenter">
          <span>平台：</span>
          <span class="num-border flex-def flex-cCenter">可创建<span class="num">{{ uni_total }}</span></span>
          <span class="num-border flex-def flex-cCenter">已创建 <span class="num">{{ list.length }}</span></span>
          <span style="color: #3196fa" class="flex-def flex-cCenter">剩余创建 <span class="num">{{ uni_total - list.length }}</span></span>
        </div>
        <el-button @click="editShow=true" type="primary" size="small">添加平台</el-button>
      </div>
    </div>
    <div style="margin-top: 1rem">
      <el-table :data="list" border style="width: 100%">
        <el-table-column label="logo" width="80" align="center">
          <template #default="s">
            <el-image style="width: 3rem;height: 3rem;border-right: .5rem" :src="s.row.logo | tomedia"></el-image>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="名称" width="180"></el-table-column>
        <el-table-column label="公众号">
          <template #default>
            <span class="y-desc">未绑定</span>
          </template>
        </el-table-column>
        <el-table-column label="小程序">
          <template #default>
            <span class="y-desc">未绑定</span>
          </template>
        </el-table-column>
        <el-table-column label="管理账号" width="180">
          <template #default="s">
            <span v-if="s.row.user">{{s.row.user.username}}</span>
            <span v-else class="y-desc">未配账号</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template #default="s">
            <el-button @click="toUni(s.row.id)" type="text" size="small">进入平台</el-button>
            <el-button @click="edit(s.row)" type="text" size="small">设置</el-button>
            <el-button @click="agentUser(s.row)" v-if="!s.row.user" type="text" size="small">分配账号</el-button>
            <el-button @click="accountFail(s.row)" v-if="s.row.user && s.row.user.founder !== 1" type="text" size="small" style="color: red">移除账号</el-button>
            <el-popconfirm v-if="s.row.user ? s.row.user.founder !== 1 : true" style="margin-left: 1rem" title="确定删除吗？" @confirm="del(s.row)">
              <el-button slot="reference" type="text" size="small" style="color: red">删除</el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
        <template #append>
          <el-pagination @current-change="pageChange" :current-page="page" :total="total" :page-size="15"  style="padding: .5rem" background layout="total,prev, pager,next"></el-pagination>
        </template>
      </el-table>
      <el-dialog title="分配账号" :visible.sync="agentUserShow" width="30rem" @close="agentUserClose" destroy-on-close>
        <el-form ref="form" :model="agentUserForm" >
          <el-form-item label="账号名称">
            <y_choose_account v-model="agentUserForm.uid" style="width: 100%"></y_choose_account>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="agentUserShow = false">取 消</el-button>
          <el-button type="primary" @click="agentUserSubmit">确 定</el-button>
        </span>
      </el-dialog>
      <el-dialog title="编辑平台信息" :visible.sync="editShow" width="30rem" @close="editClose">
        <el-form ref="form" :model="editForm" label-width="80px">
          <el-form-item label="平台名称">
            <el-input v-model="editForm.name"></el-input>
          </el-form-item>
          <el-form-item label="平台logo">
            <y_upload_img v-model="editForm.logo"></y_upload_img>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="editShow = false">取 消</el-button>
          <el-button type="primary" @click="editSubmit">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import y_upload_img from "@/components/y_upload_img";
import y_choose_account from "@/components/y_choose_account";
export default {
  name: "uni",
  components:{
    y_upload_img,
    y_choose_account
  },
  data(){
    return{
      agentUserShow:false,
      agentUserForm:{
        uni_id:0,
        uid:0
      },
      editShow:false,
      editForm:{
        logo:"",
        name:""
      },
      list:[],
      page:1,
      total:0,
      uni_total:0,
    }
  },
  mounted() {
    this.load();
    this.loadUniTotal();
  },
  methods:{
    loadUniTotal(){
      this.$api.manager.uniTotal().then(res=>{
        this.uni_total = res.uni_total;
      })
    },
    toUni(uni_acid){
      this.$router.push({
        name:"home",
        params:{uni_acid}
      })
    },
    accountFail(item){
      this.$api.manager.accountFail({id:item.user.id}).then(()=>{
        this.$message.success("操作成功");
        this.load();
      })
    },
    agentUser(item){
      this.agentUserShow = true;
      this.agentUserForm.uni_id = item.id;
    },
    agentUserSubmit(){
      this.$api.manager.bindau(this.agentUserForm).then(()=>{
        this.$message.success("操作成功");
        this.agentUserShow = false;
        this.load();
      })
    },
    agentUserClose(){
      this.agentUserForm = {
        uni_id:0,
        uid:0
      }
    },
    del(item){
      this.$api.manager.uniDel({id:item.id}).then(()=>{
        this.$message.success("操作成功");
        this.load();
      })
    },
    edit(item){
      this.editForm = {
        ...item
      };
      this.editShow = true;
    },
    editSubmit(){
      this.$api.manager.uniEdit(this.editForm).then(()=>{
        this.$message.success("操作成功");
        this.editShow = false;
        this.load();
      });
    },
    editClose(){
      this.editForm = {
        logo:"",
        name:""
      }
    },
    load(){
      this.$api.manager.uniSearch({page:this.page}).then(res=>{
        this.list = res.list;
        this.total = res.total;
      })
    },
    pageChange(cur){
      this.page = cur;
    }
  }
}
</script>

<style scoped>
.num-border{
  border-right: 1px solid #ededed;
  margin-right: .5rem;
}
.num{
  padding: 0 .5rem;
  font-size: 1rem;
  font-weight: 600;
}
</style>